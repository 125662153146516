import Vue from 'vue'
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
Vue.config.productionTip = false

import VueAnalytics from 'vue-analytics';

import App from './App.vue'

//and then use it in main.js
Vue.use(VueAnalytics, {
    id: 'UA-172472201-1'  //,
   // router
})

new Vue({
  render: h => h(App),
}).$mount('#app')
