<template>
  <div class="home">
    <a id="logo" href="https://www.hfglobaladvisors.com/" alt="HF Global Advisors">
    <img alt="WorkTuber" src="../assets/hfg.png" style="border-radius:6px;">
    </a>
    <div style="width:90%;height:100%;margin:0 auto;">
      <h1>{{ msg }}</h1>
      <div align="left" style="width: 90%;
    height: 100%;
    margin: 0 auto;padding: 5px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 15px !important;font-size: 24px;
    line-height: 32px;">HFG is a quantitative investment firm.
<br><br><br>
What we do:
<br><br>
Fintech Investments
- We are focused exclusively on financial services technology investments.
<br><br>
Product Management Services
- We help startups and established firms develop and implement new product strategies and enhance the existing product features
- CIO Office / COO / PM / CRO / CTO interim leadership functions at buy-side and fintech firms.
<br><br>
Product Development and Management Expertise:
- Investment Management Analytics Platforms. Investment Data Management
- Cloud-based Machine Learning and Data Analysis Products
<br><br><br>


      </div>
    </div>
    <div style="width:100%;float:left;text-align: center;">
                hfglobaladvisors.com © Copyright 2012-2018. All rights reserved.
     </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
      metaInfo: {
    title: 'HFG',
    titleTemplate: '%s | HF Global Advisors'
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
